.end{
    animation-name: end;
    animation-duration: 1s;
}

.start {
    animation-name: start;
    animation-duration: 1s;
}

@keyframes end {
    0% {left:0}
    100%  {left:-100%}
}

@keyframes start {
    0% {left: 100%}
    100%  {left:0}
}